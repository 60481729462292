import { CircularProgress } from "@material-ui/core";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { generateTokerAuthWithUsename, getUserAuth } from "../redux/actions";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { RootState } from "../redux/store";
const DashboardPrivateRoutes = React.lazy(() => import("./DashboarPrivateRoutes"));
const LoginPage = React.lazy(() => import("../pages/auth/LoginPage"));
export const AppRouter: React.FC<{}> = () => {
    const dispatch = useDispatch();
    // States
    const [checking, setChecking] = useState(true);
    const { isLoggedIn } = useSelector((state: RootState) => state.auth);
    const useQuery = () => {
        return new URLSearchParams(window.location.search);
    };
    const query = useQuery();

    // Effects
    useEffect(() => {
        checkAuth();
    }, []);

    //Functions
    const checkAuth = async () => {
        try {
            const params = Object.fromEntries(query);
            if (Object.entries(params).length > 0 && params.username && params.apikey) {
                const res = { ...(await dispatch(generateTokerAuthWithUsename(params.username, params.apikey))) } as {
                    payload: {
                        loading: boolean;
                        success: boolean;
                    };
                };
                if (res.payload.success) {
                    query.delete("username");
                    query.delete("apikey");
                    window.location.href = "/";
                }
            } else {
                await dispatch(getUserAuth());
            }
            setChecking(false);
        } catch (error) {}
    };

    return (
        <>
            {checking === true ? (
                <CircularProgress
                    color="primary"
                    style={{
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        margin: "auto",
                    }}
                />
            ) : (
                <Router>
                    <Suspense fallback={<div>...</div>}>
                        <Switch>
                            <PublicRoute isAuthenticated={isLoggedIn} path="/login" Component={LoginPage} />
                            <PrivateRoute isAuthenticated={isLoggedIn} path="/" Component={DashboardPrivateRoutes} />
                            <Redirect to="/" />
                        </Switch>
                    </Suspense>
                </Router>
            )}
        </>
    );
};
