import { AnyAction } from "redux";
import { ReferralOrderStore } from "../../interfaces";
import { ReferralTypes } from "../types";

const initialState: ReferralOrderStore = {
    referralOrders: null,
    selectedReferralOrders: null,
    selectedReferralPlacedOrders: null,
    meta: null,
    filters: null,
    loading: false,
    success: true,
};

export const referrals = (
    state = initialState,
    action: { type: string; payload: ReferralOrderStore } | AnyAction,
): ReferralOrderStore => {
    const { referralOrders, selectedReferralOrders, selectedReferralPlacedOrders, meta, filters, loading, success } =
        action.payload || {};
    switch (action.type) {
        case ReferralTypes.GET_REFERRAL_ORDERS_LOADING:
            return {
                ...state,
                loading,
            };
        case ReferralTypes.GET_REFERRAL_ORDERS_SUCCESS:
            return {
                ...state,
                referralOrders,
                meta,
                filters,
                loading,
                success,
            };
        case ReferralTypes.GET_REFERRAL_ORDERS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case ReferralTypes.GET_REFERRAL_ORDER_BY_ID_LOADING:
            return {
                ...state,
                loading,
            };
        case ReferralTypes.GET_REFERRAL_ORDER_BY_ID_SUCCESS:
            return {
                ...state,
                selectedReferralOrders,
                loading,
                success,
            };
        case ReferralTypes.GET_REFERRAL_ORDER_BY_ID_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case ReferralTypes.CLEAR_REFERRAL_ORDER_SUCCESS:
            return {
                ...state,
                selectedReferralOrders: null,
                loading,
            };

        case ReferralTypes.GET_REFERRAL_PLACED_ORDERS_BY_ID_LOADING:
            return {
                ...state,
                loading,
            };
        case ReferralTypes.GET_REFERRAL_PLACED_ORDERS_BY_ID_SUCCESS:
            return {
                ...state,
                selectedReferralPlacedOrders,
                loading,
                success,
            };
        case ReferralTypes.GET_REFERRAL_PLACED_ORDERS_BY_ID_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case ReferralTypes.CLEAR_REFERRAL_PLACED_ORDERS_SUCCESS:
            return {
                ...state,
                selectedReferralPlacedOrders: null,
                loading,
            };
        
        default:
            return state;
    }
};
