import { ReportStore } from './../../interfaces/report.interface';
import { AnyAction } from "redux";
import { ReportTypes } from "../types";

const initialState: ReportStore = {
    selectedReport: "",
    reportUrl: "",
    loading: false,
    success: true
};

export const report = (
    state = initialState,
    action: { type: string; payload: ReportStore } | AnyAction,
): ReportStore => {
    const { loading, success, selectedReport, reportUrl} = action.payload || {};
    switch (action.type) {
        case ReportTypes.GET_REPORT_LOADING:
            return {
                ...state,
                loading,
            };
        case ReportTypes.GET_REPORT_SUCCESS:
            return {
                ...state,
                selectedReport,
                reportUrl,
                loading,
                success,
            };
        case ReportTypes.GET_REPORT_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        default:
            return state;
    }
};
