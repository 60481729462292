import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import { IssueFilters, IssueResponse } from "../../interfaces/issue.interface";

export class IssueRepository {
    private lmBootApi2: AxiosInstance;

    constructor() {
        this.lmBootApi2 = fetcher(process.env.REACT_APP_URL_API_2 as string);
    }

    getIssues(data: IssueFilters): Promise<AxiosResponse<IssueResponse>> {
        return this.lmBootApi2.get<IssueResponse>("/issues", { params: data });
    }

    updatePaymentAuth(orderId: number, data: any): Promise<AxiosResponse<any>> {
        return this.lmBootApi2.put<IssueResponse>("/issues/" + orderId, data);
    }

    confirmOrder(orderId: number, ticketId: number): Promise<AxiosResponse<any>> {
        return this.lmBootApi2.put<IssueResponse>("/issues/confirm/" + ticketId, { orderId: orderId });
    }

    archiveOrder(orderId: number): Promise<AxiosResponse<any>> {
        return this.lmBootApi2.put<IssueResponse>("/issues/archive/" + orderId);
    }

    unArchiveOrder(orderId: number): Promise<AxiosResponse<any>> {
        return this.lmBootApi2.put<IssueResponse>("/issues/unarchive/" + orderId);
    }
}

