import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";


export class ReportRepository {
    private lmBootApi2: AxiosInstance;

    constructor() {
        this.lmBootApi2 = fetcher(process.env.REACT_APP_URL_API_2 as string);
    }

    getReport(code: string, payload: any): Promise<AxiosResponse<string>> {
        return this.lmBootApi2.post<string>(`/reports/${code}`, payload);
    }

}

