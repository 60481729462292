import { AuthRepository } from "../repositories/auth.repository";
import { AuthResponse } from "../../interfaces/auth.interface";

export class AuthProcess {
    private authRepository: AuthRepository;

    constructor() {
        this.authRepository = new AuthRepository();
    }
    async getTokenAuthWithUserName(username: string, apikey: string): Promise<AuthResponse> {
        const response = await this.authRepository.getTokenAuthWithUserName(username, apikey);
        return response.data;
    }
    async loginUserWithUsernameAndPassword(username: string, password: string): Promise<AuthResponse> {
        const response = await this.authRepository.loginUserWithUsernameAndPassword(username, password);
        return response.data;
    }
}
