import { AnyAction } from "redux";
import { AuthStore } from "../../interfaces";
import { AuthTypes } from "../types/auth.types";

const initialState: AuthStore = {
    user: null,
    token: null,
    role: null,
    userRoles: null,
    isLoggedIn: false,
    loading: false,
    success: true,
};

export const auth = (state = initialState, action: { type: string; payload: AuthStore } | AnyAction): AuthStore => {
    const { loading, success, user, token, role, userRoles, isLoggedIn } = action.payload || {};
    switch (action.type) {
        case AuthTypes.CREATE_TOKEN_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.CREATE_TOKEN_SUCCESS:
            return {
                ...state,
                user,
                token,
                role,
                userRoles,
                isLoggedIn,
                loading,
                success,
            };
        case AuthTypes.CREATE_TOKEN_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                loading,
                success,
            };
        case AuthTypes.GET_USER_AUTH_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.GET_USER_AUTH_SUCCESS:
            return {
                ...state,
                user,
                token,
                role,
                userRoles,
                isLoggedIn,
                loading,
                success,
            };
        case AuthTypes.GET_USER_AUTH_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                loading,
                success,
            };
        case AuthTypes.LOGOUT_USER_LOADING:
            return {
                ...state,
                loading,
            };
        // case AuthTypes.LOGOUT_USER_SUCCESS:
        //     return {
        //         ...state,
        //         user,
        //         token,
        //         role,
        //         isLoggedIn: false,
        //         loading,
        //         success,
        //     };
        case AuthTypes.LOGOUT_USER_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case AuthTypes.LOGIN_USER_LOADING:
            return {
                ...state,
                loading,
            };
        case AuthTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                user,
                token,
                role,
                userRoles,
                isLoggedIn,
                loading,
                success,
            };
        case AuthTypes.LOGIN_USER_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                loading,
                success,
            };
        default:
            return state;
    }
};
