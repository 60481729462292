import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { RouteProps } from "./PrivateRoute";

export const PublicRoute: React.FC<RouteProps> = ({ isAuthenticated, Component, ...rest }: RouteProps) => {
    return (
        <Route
            {...rest}
            component={(props: any) => (!isAuthenticated ? <Component {...props} /> : <Redirect to="/" />)}
        />
    );
};

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    Component: PropTypes.any.isRequired,
};
