import { IssueRepository } from "../repositories";
import { IssueResponse, IssueFilters } from "../../interfaces/issue.interface";

export class IssueProcess {
    private issueRepository: IssueRepository;

    constructor() {
        this.issueRepository = new IssueRepository();
    }

    async getIssues(filters: IssueFilters): Promise<IssueResponse> {
        const response = await this.issueRepository.getIssues(filters);
        const {
            data: { data, meta },
        } = response;
        return {
            data: data,
            meta: meta,
        };
    }

    async updatePaymentAuth(orderId: number, data: any): Promise<{ message: string }> {
        const response = await this.issueRepository.updatePaymentAuth(orderId, data);
        return { message: response.data.message };
    }

    async confirmOrder(orderId: number, ticketId: number) : Promise<{ message: string }> {
        const response = await this.issueRepository.confirmOrder(orderId, ticketId);
        return { message: response.data.message };
    }

    async archiveOrder(orderId: number) : Promise<{ message: string }> {
        const response = await this.issueRepository.archiveOrder(orderId);
        return { message: response.data.message };
    }

    async unArchiveOrder(orderId: number) : Promise<{ message: string }> {
        const response = await this.issueRepository.unArchiveOrder(orderId);
        return { message: response.data.message };
    }
}
