import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import {
    ReferralOrderFilters,
    ReferralOrderResponse,
    ReferralOrderDet
} from "../../interfaces";

export class ReferralRepository {
    private lmBootApi: AxiosInstance;
    private lmBootApi2: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API as string);
        this.lmBootApi2 = fetcher(process.env.REACT_APP_URL_API_2 as string);
    }

    getReferralOrders(data: ReferralOrderFilters): Promise<AxiosResponse<ReferralOrderResponse>> {
        return this.lmBootApi2.get<ReferralOrderResponse>("/referrals", { params: data });
    }
    getReferralOrderById(referralCode: number): Promise<AxiosResponse<ReferralOrderDet[]>> {
        return this.lmBootApi2.get<ReferralOrderDet[]>(`/referrals/${referralCode}`);
    }
    getReferralPlacedOrdersById(referralCode: number, filterReferral: boolean): Promise<AxiosResponse<ReferralOrderDet[]>> {
        return this.lmBootApi2.get<ReferralOrderDet[]>(`/referrals/${referralCode}/orders/${filterReferral}`);
    }

    exportReport(data: any): Promise<AxiosResponse<Blob>> {
        return this.lmBootApi2.post<Blob>(`api/tools/export`, data);
    }
}

