import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../../../redux/actions/alert";
import { RootState } from "../../../redux/store";

export const SnackbarMessage: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const [showMessage, setShowMessage] = useState(true);
    const { isOpen, typeMessage, message } = useSelector((state: RootState) => state.alerts);
    useEffect(() => {
        if (isOpen) {
            setShowMessage(true);
        }
    }, [isOpen, typeMessage, message]);
    const showSuccess = () => (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isOpen}
            autoHideDuration={6000}
            onClose={handleHideSnackbar}
        >
            <Alert onClose={handleHideSnackbar} severity="success">
                {message}
            </Alert>
        </Snackbar>
    );

    const showInfo = () => (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isOpen}
            autoHideDuration={6000}
            onClose={handleHideSnackbar}
        >
            <Alert onClose={handleHideSnackbar} severity="info">
                {message}
            </Alert>
        </Snackbar>
    );

    const showWarning = () => (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isOpen}
            autoHideDuration={6000}
            onClose={handleHideSnackbar}
        >
            <Alert onClose={handleHideSnackbar} severity="warning">
                {message}
            </Alert>
        </Snackbar>
    );

    const showError = () => (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isOpen}
            autoHideDuration={6000}
            onClose={handleHideSnackbar}
        >
            <Alert onClose={handleHideSnackbar} severity="error">
                {message}
            </Alert>
        </Snackbar>
    );

    const handleHideSnackbar = () => {
        dispatch(hideAlert());
    };
    const renderMessage = () => {
        switch (typeMessage) {
            case "success":
                return showSuccess();
            case "info":
                return showInfo();
            case "warning":
                return showWarning();
            case "error":
                return showError();
            default:
                return "";
        }
    };
    return <div>{showMessage && renderMessage()}</div>;
};
