export enum IncidenceTypes {
    GET_INCIDENCES_LOADING = "GET_INCIDENCES_LOADING",
    GET_INCIDENCES_SUCCESS = "GET_INCIDENCES_SUCCESS",
    GET_INCIDENCES_FAILURE = "GET_INCIDENCES_FAILURE",
    GET_INCIDENCE_STATUS_LOADING = "GET_INCIDENCE_STATUS_LOADING",
    GET_INCIDENCE_STATUS_SUCCESS = "GET_INCIDENCE_STATUS_SUCCESS",
    GET_INCIDENCE_STATUS_FAILURE = "GET_INCIDENCE_STATUS_FAILURE",
    UPDATE_INCIDENCE_STATUS_LOADING = "UPDATE_INCIDENCE_STATUS_LOADING",
    UPDATE_INCIDENCE_STATUS_SUCCESS = "UPDATE_INCIDENCE_STATUS_SUCCESS",
    UPDATE_INCIDENCE_STATUS_FAILURE = "UPDATE_INCIDENCE_STATUS_FAILURE",
    UPDATE_INCIDENCE_MEASURES_LOADING = "UPDATE_INCIDENCE_MEASURES_LOADING",
    UPDATE_INCIDENCE_MEASURES_SUCCESS = "UPDATE_INCIDENCE_MEASURES_SUCCESS",
    UPDATE_INCIDENCE_MEASURES_FAILURE = "UPDATE_INCIDENCE_MEASURES_FAILURE",
    UPDATE_INCIDENCE_RESOLUTION_LOADING = "UPDATE_INCIDENCE_RESOLUTION_LOADING",
    UPDATE_INCIDENCE_RESOLUTION_SUCCESS = "UPDATE_INCIDENCE_RESOLUTION_SUCCESS",
    UPDATE_INCIDENCE_RESOLUTION_FAILURE = "UPDATE_INCIDENCE_RESOLUTION_FAILURE",
    GET_INCIDENCE_BY_ID_LOADING = "GET_INCIDENCE_BY_ID_LOADING",
    GET_INCIDENCE_BY_ID_SUCCESS = "GET_INCIDENCE_BY_ID_SUCCESS",
    GET_INCIDENCE_BY_ID_FAILURE = "GET_INCIDENCE_BY_ID_FAILURE",
    CLEAR_INCIDENCE_SUCCESS = "CLEAR_INCIDENCE_SUCCESS",
    GET_INCIDENCE_VIDEOS_LOADING = "GET_INCIDENCE_VIDEOS_LOADING",
    GET_INCIDENCE_VIDEOS_SUCCESS = "GET_INCIDENCE_VIDEOS_SUCCESS",
    GET_INCIDENCE_VIDEOS_FAILURE = "GET_INCIDENCE_VIDEOS_FAILURE",
    CLEAR_INCIDENCE_VIDEOS_SUCCESS = "CLEAR_INCIDENCE_VIDEOS_SUCCESS",
    ASSOCIATE_INCIDENCE_IMAGES_LOADING = "ASSOCIATE_INCIDENCE_IMAGES_LOADING",
    ASSOCIATE_INCIDENCE_IMAGES_SUCCESS = "ASSOCIATE_INCIDENCE_IMAGES_SUCCESS",
    ASSOCIATE_INCIDENCE_IMAGES_FAILURE = "ASSOCIATE_INCIDENCE_IMAGES_FAILURE",
    ASSOCIATE_INCIDENCE_CUSTOMER_NOTES_LOADING = "ASSOCIATE_INCIDENCE_CUSTOMER_NOTES_LOADING",
    ASSOCIATE_INCIDENCE_CUSTOMER_NOTES_SUCCESS = "ASSOCIATE_INCIDENCE_CUSTOMER_NOTES_SUCCESS",
    ASSOCIATE_INCIDENCE_CUSTOMER_NOTES_FAILURE = "ASSOCIATE_INCIDENCE_CUSTOMER_NOTES_FAILURE",
    GET_VESTIMENTAS_LOADING = "GET_VESTIMENTAS_LOADING",
    GET_VESTIMENTAS_SUCCESS = "GET_VESTIMENTAS_SUCCESS",
    GET_VESTIMENTAS_FAILURE = "GET_VESTIMENTAS_FAILURE",
    SET_INCIDENCE_FILTER_DATE = "SET_INCIDENCE_FILTER_DATE",
    SET_INCIDENCE_FILTER_STATUS = "SET_INCIDENCE_FILTER_STATUS",
    SET_INCIDENCE_FILTER_CUSTOMER = "SET_INCIDENCE_FILTER_CUSTOMER",
    SET_INCIDENCE_FILTER_ORDER = "SET_INCIDENCE_FILTER_ORDER"
}
