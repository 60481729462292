export enum IssueTypes {
    GET_ISSUES_LOADING = "GET_ISSUES_LOADING",
    GET_ISSUES_SUCCESS = "GET_ISSUES_SUCCESS",
    GET_ISSUES_FAILURE = "GET_ISSUES_FAILURE",
    UPDATE_ISSUE_PAYMENT_AUTH_LOADING = "UPDATE_ISSUE_PAYMENT_AUTH_LOADING",
    UPDATE_ISSUE_PAYMENT_AUTH_SUCCESS = "UPDATE_ISSUE_PAYMENT_AUTH_SUCCESS",
    UPDATE_ISSUE_PAYMENT_AUTH_FAILURE = "UPDATE_ISSUE_PAYMENT_AUTH_FAILURE",
    CONFIRM_ORDER_LOADING = "CONFIRM_ORDER_LOADING",
    CONFIRM_ORDER_SUCCESS = "CONFIRM_ORDER_SUCCESS",
    CONFIRM_ORDER_FAILURE = "CONFIRM_ORDER_FAILURE",
    ARCHIVE_ORDER_LOADING = "ARCHIVE_ORDER_LOADING",
    ARCHIVE_ORDER_SUCCESS = "ARCHIVE_ORDER_SUCCESS",
    ARCHIVE_ORDER_FAILURE = "ARCHIVE_ORDER_FAILURE",
}
