import {
    DocumentHistory
} from "../../interfaces";
import { DocumentHistoryRepository } from "../repositories";
export class DocumentHistoryProcess {
    private documentHistoryRepository: DocumentHistoryRepository;

    constructor() {
        this.documentHistoryRepository = new DocumentHistoryRepository();
    }

    async getDocumentHistory(orderId: number): Promise<DocumentHistory[]> {
        const response = await this.documentHistoryRepository.getDocumentHistoryOrders(orderId);
        return response.data;
    }

    async addDocumentHistory(orderId: number, formData: any): Promise<{ message: string }> {
        const response = await this.documentHistoryRepository.addDocumentHistory(orderId, formData);
        return { message: response.data.message };
    }
}
