import { AnyAction } from "redux";
import { OrderStore } from "../../interfaces";
import { OrderTypes } from "../types";

const initialState: OrderStore = {
    orderList: null,
    selectedOrdersInfo: null,
    filters: null,
    stepList: null,
    loading: false,
    success: true,
};

export const orders = (state = initialState, action: { type: string; payload: OrderStore } | AnyAction): OrderStore => {
    const { loading, success, orderList, filters, stepList } = action.payload || {};
    switch (action.type) {
        case OrderTypes.GET_ORDERS_LOADING:
            return {
                ...state,
                loading,
            };
        case OrderTypes.GET_ORDERS_SUCCESS:
            return {
                ...state,
                orderList,
                filters,
                loading,
                success,
            };
        case OrderTypes.GET_ORDERS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case OrderTypes.CLEAR_ORDERS_SUCCESS:
            return {
                ...state,
                selectedOrdersInfo: null,
            };
        case OrderTypes.GET_STEPS_LOADING:
            return {
                ...state,
                loading,
            };
        case OrderTypes.GET_STEPS_SUCCESS:
            return {
                ...state,
                stepList,
            };
        case OrderTypes.GET_STEPS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        default:
            return state;
    }
};
