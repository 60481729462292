import { createStore, applyMiddleware, compose, combineReducers, AnyAction, Reducer } from "redux";
import thunk from "redux-thunk";
import * as reducers from "./reducers";

interface ExtendedWindow extends Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare let window: ExtendedWindow;

const appReducer = combineReducers(reducers);

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === "LOGOUT_USER_SUCCESS") {
        // this applies to all keys defined in persistConfig(s)
        state = {} as RootState;
    }
    return appReducer(state, action);
};
const composeEnhancers = (typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
