import {
    VerificationError,
    VerificationOrder,
    VerificationOrderFilters,
    VerificationOrderResponse,
    VerificationStatistics,
} from "../../interfaces";
import { VerificationRepository } from "../repositories";
export class VerificationProcess {
    private verificationRepository: VerificationRepository;

    constructor() {
        this.verificationRepository = new VerificationRepository();
    }

    async getVerificationOrders(filters: VerificationOrderFilters): Promise<VerificationOrderResponse> {
        const response = await this.verificationRepository.getVerificationOrders(filters);
        const {
            data: { data, meta },
        } = response;
        return {
            data: data,
            meta: meta,
        };
    }
    async getVerificationOrderById(orderCode: number): Promise<VerificationOrder> {
        const response = await this.verificationRepository.getVerificationOrderById(orderCode);
        return response.data;
    }
    async createVerificationErrors(dataError: VerificationError): Promise<{ message: string }> {
        const response = await this.verificationRepository.createVerificationErrors(dataError);
        const { data } = response;
        return { message: data.message };
    }
    async nextStepVerification(orderCode: number): Promise<{ message: string }> {
        const response = await this.verificationRepository.nextStepVerification(orderCode);
        const { data } = response;
        return { message: data.message };
    }
    async getVerificationStatistics(): Promise<VerificationStatistics[]> {
        const response = await this.verificationRepository.getVerificationStatistics();
        return response.data;
    }
}
