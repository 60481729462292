import { AlertTypes } from "../types/alert.types";
import { Dispatch } from "redux";
export const showAlert =
    (typeMessage: string, message: string) =>
    async (
        dispatch: Dispatch,
    ): Promise<{
        type: AlertTypes;
        payload: {
            loading: boolean;
            success: boolean;
        };
    }> => {
        return dispatch({
            type: AlertTypes.SNACKBAR_SUCCESS,
            payload: { typeMessage, message, loading: true, success: true },
        });
    };

export const hideAlert =
    () =>
    async (
        dispatch: Dispatch,
    ): Promise<{
        type: AlertTypes;
        payload: {
            loading: boolean;
            success: boolean;
        };
    }> => {
        return dispatch({
            type: AlertTypes.SNACKBAR_HIDE,
            payload: { loading: true, success: true },
        });
    };

export const clearAlert =
    () =>
    async (
        dispatch: Dispatch,
    ): Promise<{
        type: AlertTypes;
        payload: {
            loading: boolean;
            success: boolean;
        };
    }> => {
        return dispatch({
            type: AlertTypes.SNACKBAR_CLEAR,
            payload: { loading: true, success: true },
        });
    };
