import { AuthTypes } from "../types/auth.types";
import { AuthProcess } from "../../api/process/auth.process";
import * as localStorage from "../../utils/localStorage";
import { User, Token, Role } from "../../interfaces/auth.interface";
import { showAlert } from "./alert";
export const generateTokerAuthWithUsename =
    (username: string, apikey: string) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: AuthTypes.CREATE_TOKEN_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const authProcess = new AuthProcess();
            const response = await authProcess.getTokenAuthWithUserName(username, apikey);
            localStorage.saveObject("user", response.user);
            localStorage.saveObject("token", response.token);
            localStorage.saveObject("role", response.role);
            const userRoles: number[] = [];
            const enableRoles: number[] = [2, 17, 26, 27];
            let isEnableLogin = false;
            response.role.map((item) => userRoles.push(item.code));
            enableRoles.map((rol) => {
                if (userRoles.find((item) => item === rol)) {
                    isEnableLogin = true;
                }
            });
            if (!isEnableLogin) {
                dispatch(showAlert("error", "You do not have sufficient permissions to access the system!"));
                localStorage.clear();
                return dispatch({
                    type: AuthTypes.CREATE_TOKEN_FAILURE,
                    payload: {
                        loading: false,
                        success: false,
                    },
                });
            }
            return dispatch({
                type: AuthTypes.CREATE_TOKEN_SUCCESS,
                payload: {
                    user: response.user,
                    token: response.token,
                    role: response.role,
                    userRoles: userRoles,
                    isLoggedIn: true,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            localStorage.clear();
            dispatch(showAlert("error", "Wrong credentials!"));
            return dispatch({
                type: AuthTypes.CREATE_TOKEN_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const loginUser =
    (username: string, password: string) => async (dispatch: (arg: Record<string, any>) => any) => {
        // dispatch({
        //     type: AuthTypes.LOGIN_USER_LOADING,
        //     payload: {
        //         loading: true,
        //     },
        // });
        try {
            const authProcess = new AuthProcess();
            const response = await authProcess.loginUserWithUsernameAndPassword(username, password);
            localStorage.saveObject("user", response.user);
            localStorage.saveObject("token", response.token);
            localStorage.saveObject("role", response.role);
            const userRoles: number[] = [];
            const enableRoles: number[] = [2, 17, 26, 27];
            let isEnableLogin = false;
            response.role.map((item) => userRoles.push(item.code));
            enableRoles.map((rol) => {
                if (userRoles.find((item) => item === rol)) {
                    isEnableLogin = true;
                }
            });
            if (!isEnableLogin) {
                dispatch(showAlert("error", "You do not have sufficient permissions to access the system!"));
                localStorage.clear();
                return dispatch({
                    type: AuthTypes.LOGIN_USER_FAILURE,
                    payload: {
                        loading: false,
                        success: false,
                    },
                });
            }
            return dispatch({
                type: AuthTypes.LOGIN_USER_SUCCESS,
                payload: {
                    user: response.user,
                    token: response.token,
                    role: response.role,
                    userRoles: userRoles,
                    isLoggedIn: true,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            localStorage.clear();
            dispatch(showAlert("error", "Wrong credentials!"));
            return dispatch({
                type: AuthTypes.LOGIN_USER_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const getUserAuth = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: AuthTypes.GET_USER_AUTH_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const user = localStorage.loadObject("user") as User;
        const token = localStorage.loadObject("token") as Token;
        const role = localStorage.loadObject("role") as Role[];
        const userRoles: number[] = [];
        role.map((item) => userRoles.push(item.code));
        if (user && token) {
            return dispatch({
                type: AuthTypes.GET_USER_AUTH_SUCCESS,
                payload: {
                    user: user,
                    token: token,
                    role: role,
                    userRoles: userRoles,
                    isLoggedIn: true,
                    loading: false,
                    success: true,
                },
            });
        } else {
            return dispatch({
                type: AuthTypes.GET_USER_AUTH_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    } catch (error) {
        localStorage.clear();
        return dispatch({
            type: AuthTypes.GET_USER_AUTH_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
export const logoutUser = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: AuthTypes.LOGOUT_USER_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        localStorage.clear();
        return dispatch({
            type: AuthTypes.LOGOUT_USER_SUCCESS,
            payload: {
                user: null,
                token: null,
                role: null,
                userRoles: null,
                isLoggedIn: false,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: AuthTypes.LOGOUT_USER_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
