import { AnyAction } from "redux";
import { IncidenceStore } from "../../interfaces";
import { IncidenceTypes } from "../types";

const initialState: IncidenceStore = {
    incidences: null,
    meta: null,
    selectedIncidence: null,
    filters: null,
    incidenceStatus: null,
    incidenceVideos: null,
    loading: false,
    success: true,
    vestimentas: null,
    filterDate: '',
    filterStatus: 'all',
    filterCustomer: '',
    filterOrder: '',
};

export const incidences = (
    state = initialState,
    action: { type: string; payload: IncidenceStore } | AnyAction,
): IncidenceStore => {
    const { 
        loading, success, incidences, meta, filters, incidenceStatus, selectedIncidence, incidenceVideos, vestimentas, filterDate, filterStatus, filterCustomer, filterOrder
    } = action.payload || {};
    switch (action.type) {
        case IncidenceTypes.GET_INCIDENCES_LOADING:
            return {
                ...state,
                loading,
                selectedIncidence: null
            };
        case IncidenceTypes.GET_INCIDENCES_SUCCESS:
            return {
                ...state,
                incidences,
                meta,
                filters,
                loading,
                success,
                selectedIncidence: null
            };
        case IncidenceTypes.GET_INCIDENCES_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.GET_INCIDENCE_STATUS_LOADING:
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.GET_INCIDENCE_STATUS_SUCCESS:
            return {
                ...state,
                incidenceStatus,
                loading,
                success,
            };
        case IncidenceTypes.GET_INCIDENCE_STATUS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.UPDATE_INCIDENCE_STATUS_LOADING:
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.UPDATE_INCIDENCE_STATUS_SUCCESS:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.UPDATE_INCIDENCE_STATUS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.UPDATE_INCIDENCE_MEASURES_LOADING:
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.UPDATE_INCIDENCE_MEASURES_SUCCESS:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.UPDATE_INCIDENCE_MEASURES_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_LOADING:
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_SUCCESS:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.GET_INCIDENCE_BY_ID_LOADING:
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.GET_INCIDENCE_BY_ID_SUCCESS:
            return {
                ...state,
                selectedIncidence,
                loading,
            };
        case IncidenceTypes.GET_INCIDENCE_BY_ID_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.CLEAR_INCIDENCE_SUCCESS:
            return {
                ...state,
                selectedIncidence: null,
                loading,
            };
        case IncidenceTypes.GET_INCIDENCE_VIDEOS_LOADING:
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.GET_INCIDENCE_VIDEOS_SUCCESS:
            return {
                ...state,
                incidenceVideos,
                loading,
            };
        case IncidenceTypes.GET_INCIDENCE_VIDEOS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case IncidenceTypes.CLEAR_INCIDENCE_VIDEOS_SUCCESS:
            return {
                ...state,
                incidenceVideos: null,
                loading,
            };
        case IncidenceTypes.ASSOCIATE_INCIDENCE_IMAGES_LOADING: 
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.ASSOCIATE_INCIDENCE_IMAGES_SUCCESS: 
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.ASSOCIATE_INCIDENCE_IMAGES_FAILURE: 
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.GET_VESTIMENTAS_LOADING: 
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.GET_VESTIMENTAS_SUCCESS: 
            return {
                ...state,
                vestimentas,
                loading,
            };
        case IncidenceTypes.GET_VESTIMENTAS_FAILURE: 
            return {
                ...state,
                loading,
            };
        case IncidenceTypes.SET_INCIDENCE_FILTER_DATE:
            return {
                ...state,
                filterDate,
            };
        case IncidenceTypes.SET_INCIDENCE_FILTER_STATUS:
            return {
                ...state,
                filterStatus,
            };
        case IncidenceTypes.SET_INCIDENCE_FILTER_CUSTOMER:
            return {
                ...state,
                filterCustomer,
            };
        case IncidenceTypes.SET_INCIDENCE_FILTER_ORDER:
            return {
                ...state,
                filterOrder,
            };
        default:
            return state;
    }
};
