import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

export type RouteProps = {
    isAuthenticated: boolean;
    Component: React.LazyExoticComponent<React.FC<{}>>;
    path: string;
};

export const PrivateRoute: React.FC<RouteProps> = ({ isAuthenticated, Component, ...rest }: RouteProps) => {
    return (
        <Route
            {...rest}
            component={(props: any) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)}
        />
    );
};

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    Component: PropTypes.any.isRequired,
};
