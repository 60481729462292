export enum AuthTypes {
    CREATE_TOKEN_LOADING = "CREATE_TOKEN_LOADING",
    CREATE_TOKEN_SUCCESS = "CREATE_TOKEN_SUCCESS",
    CREATE_TOKEN_FAILURE = "CREATE_TOKEN_FAILURE",
    GET_USER_AUTH_LOADING = "GET_USER_AUTH_LOADING",
    GET_USER_AUTH_SUCCESS = "GET_USER_AUTH_SUCCESS",
    GET_USER_AUTH_FAILURE = "GET_USER_AUTH_FAILURE",
    LOGOUT_USER_LOADING = "LOGOUT_USER_LOADING",
    LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS",
    LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE",
    LOGIN_USER_LOADING = "LOGIN_USER_LOADING",
    LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS",
    LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE",
}
