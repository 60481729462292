import { AnyAction } from "redux";
import { IssueStore } from "../../interfaces";
import { IssueTypes } from "../types";

const initialState: IssueStore = {
    issueOrders: null,
    meta: null,
    filters: null,
    loading: false,
    success: true
};

export const issues = (
    state = initialState,
    action: { type: string; payload: IssueStore } | AnyAction,
): IssueStore => {
    const { 
        loading, success, issueOrders, meta, filters
    } = action.payload || {};
    switch (action.type) {
        case IssueTypes.GET_ISSUES_LOADING:
            return {
                ...state,
                loading
            };
        case IssueTypes.GET_ISSUES_SUCCESS:
            return {
                ...state,
                issueOrders,
                meta,
                filters,
                loading,
                success
            };
        case IssueTypes.GET_ISSUES_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        case IssueTypes.UPDATE_ISSUE_PAYMENT_AUTH_LOADING:
            return {
                ...state,
                loading
            };
        case IssueTypes.UPDATE_ISSUE_PAYMENT_AUTH_SUCCESS:
            return {
                ...state,
                loading,
                success
            };
        case IssueTypes.UPDATE_ISSUE_PAYMENT_AUTH_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        default:
            return state;
    }
};
