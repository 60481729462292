import { AlertTypes } from "../types";
import { AlertStore } from "../../interfaces/alerts.interface";
import { AnyAction } from "redux";

const initialState: AlertStore = {
    typeMessage: "",
    message: "",
    isOpen: false,
    loading: true,
    success: true,
};

export const alerts = (state = initialState, action: { type: string; payload: AlertStore } | AnyAction): AlertStore => {
    const { loading, success, typeMessage, message } = action.payload || {};
    switch (action.type) {
        case AlertTypes.SNACKBAR_SUCCESS:
            return {
                ...state,
                isOpen: true,
                typeMessage,
                message,
                loading,
                success,
            };
        case AlertTypes.SNACKBAR_HIDE:
            return {
                ...initialState,
            };
        case AlertTypes.SNACKBAR_CLEAR:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};
