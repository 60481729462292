import { TransactionTypes } from "../types";
import { TransactionProcess } from "../../api/process";
import { TransactionFilters } from "../../interfaces";

export const getTransactionsAction =
    (filters: TransactionFilters) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: TransactionTypes.GET_TRANSACTIONS_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const transactionProcess = new TransactionProcess();
            const data = await transactionProcess.getTransactions(filters);
            return dispatch({
                type: TransactionTypes.GET_TRANSACTIONS_SUCCESS,
                payload: {
                    transactionList: data,
                    filters,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            console.log(error);

            return dispatch({
                type: TransactionTypes.GET_TRANSACTIONS_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

export const clearTransactions = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: TransactionTypes.CLEAR_TRANSACTIONS_SUCCESS,
    });
};
