import { CommissionRepository } from "../repositories";
import {
    CommissionFilters,
    CommissionRequest,
    PayCommissions,
    PaymentMethod,
} from "../../interfaces/commission.interface";

export class CommissionProcess {
    private commissionRepository: CommissionRepository;

    constructor() {
        this.commissionRepository = new CommissionRepository();
    }

    async getCommissions(filters: CommissionFilters): Promise<CommissionRequest> {
        const response = await this.commissionRepository.getCommissions(filters);
        const {
            data: { data, meta },
        } = response;
        return {
            commissionData: data,
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
            hasPreviousPage: meta.hasPreviousPage,
            hasNextPage: meta.hasNextPage,
        };
    }
    async getCommissionsInfo(commissionsIds: string): Promise<CommissionRequest> {
        const response = await this.commissionRepository.getCommissionsInfo(commissionsIds);
        const { data } = response;
        return {
            selectedCommissionsInfo: data,
        } as CommissionRequest;
    }
    async getWalletByClientCode(clientCode: string): Promise<CommissionRequest> {
        const response = await this.commissionRepository.getWalletByClientCode(clientCode);
        const { data } = response;
        return {
            wallet: data,
        } as CommissionRequest;
    }
    async createPayCommissions(payData: PayCommissions): Promise<{ message: string }> {
        const response = await this.commissionRepository.createPayCommissions(payData);
        const { data } = response;
        return { message: data.message };
    }
    async getPaymentMethodList(): Promise<PaymentMethod[]> {
        const response = await this.commissionRepository.getPaymentMethodList();
        return response.data;
    }
}
