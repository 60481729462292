import { AnyAction } from "redux";
import { DocumentHistoryStore } from "../../interfaces";
import { DocumentHistoryTypes } from "../types";

const initialState: DocumentHistoryStore = {
    documentHistory: [],
    loading: false,
    success: true,
};

export const documentHistory = (
    state = initialState,
    action: { type: string; payload: DocumentHistoryStore } | AnyAction,
): DocumentHistoryStore => {
    const { documentHistory, loading, success } =
        action.payload || {};
    switch (action.type) {
        case DocumentHistoryTypes.GET_DOCUMENT_HISTORY_LOADING:
            return {
                ...state,
                loading,
            };
        case DocumentHistoryTypes.GET_DOCUMENT_HISTORY_SUCCESS:
            return {
                ...state,
                documentHistory,
                loading,
                success,
            };
        case DocumentHistoryTypes.GET_DOCUMENT_HISTORY_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
       
        
        default:
            return state;
    }
};
