import { DocumentHistoryProcess } from "../../api";
import { DocumentHistoryTypes } from "../types";

export const getDocumentHistoryAction = (orderId: number) => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: DocumentHistoryTypes.GET_DOCUMENT_HISTORY_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const documentHistoryProcess = new DocumentHistoryProcess();
        const response = await documentHistoryProcess.getDocumentHistory(orderId);
        return dispatch({
            type: DocumentHistoryTypes.GET_DOCUMENT_HISTORY_SUCCESS,
            payload: {
                documentHistory: response,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: DocumentHistoryTypes.GET_DOCUMENT_HISTORY_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};

export const addDocumentHistoryAction = (orderId: number, formData: any) => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: DocumentHistoryTypes.CREATE_DOCUMENT_HISTORY_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const documentHistoryProcess = new DocumentHistoryProcess();
        const response = await documentHistoryProcess.addDocumentHistory(orderId, formData);
        console.log('response: ', response);
        setTimeout(dispatch(getDocumentHistoryAction(orderId)), 500);
        return dispatch({
            type: DocumentHistoryTypes.CREATE_DOCUMENT_HISTORY_SUCCESS,
            payload: {
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        console.log('error: ', error);
        return dispatch({
            type: DocumentHistoryTypes.CREATE_DOCUMENT_HISTORY_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
