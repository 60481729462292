import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import {
    DocumentHistory
} from "../../interfaces";

export class DocumentHistoryRepository {
    private lmBootApi: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API_2 as string);
    }

    getDocumentHistoryOrders(orderId: number): Promise<AxiosResponse<DocumentHistory[]>> {
        return this.lmBootApi.get<DocumentHistory[]>("/document-history/" + orderId);
    }

    addDocumentHistory(orderId:number, formData: any): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.post<{ message: string }>("/document-history/" + orderId, formData, {headers: { "Content-Type": "multipart/form-data" }});
    }
}

