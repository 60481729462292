import {
    ReferralOrderFilters,
    ReferralOrderResponse,
    ReferralOrderDet
} from "../../interfaces";
import { ReferralRepository } from "../repositories";
export class ReferralProcess {
    private referralRepository: ReferralRepository;

    constructor() {
        this.referralRepository = new ReferralRepository();
    }

    async getReferralOrders(filters: ReferralOrderFilters): Promise<ReferralOrderResponse> {
        const response = await this.referralRepository.getReferralOrders(filters);
        const {
            data: { data, meta },
        } = response;
        return {
            data: data,
            meta: meta,
        };
    }
    async getReferralOrderById(referralCode: number): Promise<ReferralOrderDet[]> {
        const response = await this.referralRepository.getReferralOrderById(referralCode);
        return response.data;
    }

    async getReferralPlacedOrdersById(referralCode: number, filterReferral: boolean): Promise<ReferralOrderDet[]> {
        const response = await this.referralRepository.getReferralPlacedOrdersById(referralCode, filterReferral);
        return response.data;
    }

    async exportReport(data: any): Promise<Blob> {
        const response = await this.referralRepository.exportReport(data);
        return response.data;
    }

}
