export enum ReferralTypes {
    GET_REFERRAL_ORDERS_LOADING = "GET_REFERRAL_ORDERS_LOADING",
    GET_REFERRAL_ORDERS_SUCCESS = "GET_REFERRAL_ORDERS_SUCCESS",
    GET_REFERRAL_ORDERS_FAILURE = "GET_REFERRAL_ORDERS_FAILURE",
    GET_REFERRAL_ORDER_BY_ID_LOADING = "GET_REFERRAL_ORDER_BY_ID_LOADING",
    GET_REFERRAL_ORDER_BY_ID_SUCCESS = "GET_REFERRAL_ORDER_BY_ID_SUCCESS",
    GET_REFERRAL_ORDER_BY_ID_FAILURE = "GET_REFERRAL_ORDER_BY_ID_FAILURE",
    CLEAR_REFERRAL_ORDER_SUCCESS = "CLEAR_REFERRAL_ORDER_SUCCESS",
    GET_REFERRAL_PLACED_ORDERS_BY_ID_LOADING = "GET_REFERRAL_PLACED_ORDERS_BY_ID_LOADING",
    GET_REFERRAL_PLACED_ORDERS_BY_ID_SUCCESS = "GET_REFERRAL_PLACED_ORDERS_BY_ID_SUCCESS",
    GET_REFERRAL_PLACED_ORDERS_BY_ID_FAILURE = "GET_REFERRAL_PLACED_ORDERS_BY_ID_FAILURE",
    CLEAR_REFERRAL_PLACED_ORDERS_SUCCESS = "CLEAR_REFERRAL_PLACED_ORDERS_SUCCESS",
    EXPORT_REPORTS_LOADING = "EXPORT_REPORTS_LOADING",
    EXPORT_REPORTS_SUCCESS = "CLEAR_REFEXPORT_REPORTS_SUCCESSERRAL_PLACED_ORDERS_SUCCESS",
    EXPORT_REPORTS_FAILURE = "EXPORT_REPORTS_FAILURE"
}
